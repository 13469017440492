/**
 * Utility to prevent mouse wheel from changing number input values
 * by automatically blurring the input when wheel event is detected
 */

/**
 * Handle wheel event on number inputs by blurring them
 */
const handleNumberInputWheel = (event: Event): void => {
  // Blur the input to prevent the wheel event from changing its value
  (event.target as HTMLElement).blur();
};

/**
 * Attach wheel event listener to a number input
 */
const attachWheelListener = (input: HTMLInputElement): void => {
  input.addEventListener('wheel', handleNumberInputWheel, { passive: false });
};

/**
 * Find all number inputs in the document and attach wheel event listeners
 */
const attachListenersToExistingInputs = (): void => {
  const numberInputs = document.querySelectorAll('input[type="number"]');
  numberInputs.forEach((input) => {
    attachWheelListener(input as HTMLInputElement);
  });
};

/**
 * Initialize a MutationObserver to watch for dynamically added number inputs
 */
const initMutationObserver = (): void => {
  // Create a MutationObserver to watch for dynamically added number inputs
  const observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      if (mutation.type === 'childList') {
        mutation.addedNodes.forEach((node) => {
          // Check if the added node is an element
          if (node.nodeType === Node.ELEMENT_NODE) {
            // Check if the node itself is a number input
            if (
              (node as Element).tagName === 'INPUT' && 
              (node as HTMLInputElement).type === 'number'
            ) {
              attachWheelListener(node as HTMLInputElement);
            }
            
            // Check for number inputs within the added node
            const numberInputs = (node as Element).querySelectorAll('input[type="number"]');
            numberInputs.forEach((input) => {
              attachWheelListener(input as HTMLInputElement);
            });
          }
        });
      }
    });
  });

  // Start observing the document with the configured parameters
  observer.observe(document.body, {
    childList: true,
    subtree: true
  });
};

/**
 * Initialize the targeted event listeners for number inputs
 */
export function initNumberInputWheelBlur(): void {
  // Wait for the DOM to be fully loaded
  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', () => {
      attachListenersToExistingInputs();
      initMutationObserver();
    });
  } else {
    // DOM is already loaded
    attachListenersToExistingInputs();
    initMutationObserver();
  }
} 